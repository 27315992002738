body {
  margin: 0;
  padding: 0;
  font-family: 'Satoshi';
  background-color: #35477d;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #ff919d;
  text-decoration: none;
}

a:hover {
  color: #ff919d;
  text-decoration: underline;
}


  @font-face {
  font-family: 'Atomed Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Atomed Regular'), url('./assets/font/Atomed-x31W8.woff') format('woff');
  }

  @font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Regular'), url('./assets/font/Satoshi-Regular.woff') format('woff'),
    url('./assets/font/Satoshi-Italic.woff') format('woff'),
url('./assets/font/Satoshi-Light.woff') format('woff'),
url('./assets/font/Satoshi-LightItalic.woff') format('woff'),
url('./assets/font/Satoshi-Medium.woff') format('woff'),
url('./assets/font/Satoshi-MediumItalic.woff') format('woff');
    }
    

    /* @font-face {
    font-family: 'Satoshi Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Italic'), url('./assets/font/Satoshi-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Light';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Light'), url('./assets/font/Satoshi-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Light Italic'), url('./assets/font/Satoshi-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Medium'), url('./assets/font/Satoshi-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Medium Italic'), url('./assets/font/Satoshi-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Bold'), url('./assets/font/Satoshi-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Bold Italic'), url('./assets/font/Satoshi-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Black';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Black'), url('./assets/font/Satoshi-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Satoshi Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Satoshi Black Italic'), url('./assets/font/Satoshi-BlackItalic.woff') format('woff');
    } */


/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #3c5395;
}

.main_navbar {
  background-color: transparent !important;
  z-index: 100;
  transition: 0.2s all;
}

.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #3c5395 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 1020;
  padding: 15px 0;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}

.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}

.logout_btn img {
  margin-right: 10px;
}
.bgimg{
  background: url("../src/assets/images/banimg1.png") no-repeat center;
  background-position-y:0 ;
}
.page_header {
  position: relative;  
  overflow: hidden;
  padding: 190px 0 300px;
}
.button{
  background: linear-gradient(45deg, #6b90ff, #6b90ff 100%), linear-gradient(135deg, #6b90ff, #6b90ff), linear-gradient(134deg ,#6b90ff 40%, #F6727E 49%, #35477d 65% ,#6b8fff 0% ) , linear-gradient(225deg, #6b90ff, #6b90ff);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 130% 0%, 100% 100%;
  background-repeat: no-repeat;
  background-color: #6b8fff;
}
.page_header h1 {
  font-size: 156px;
  font-weight: 400;
  color: #fff;
  z-index: 50; 
  font-family: 'Atomed Regular';
  text-shadow: 0 0px 13px #1d2d5f;
  text-shadow: -3px 1px 22px #000000cc;
  margin-bottom: 0;
}

.page_header h5 {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
  color: #fff;
  z-index: 50;
  /* position: absolute;
  right: 0;
  bottom: 0; */
  max-width: 25%;
}
.page_header h6 {
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  text-shadow: 4px 6px 10px #1d2d5f;
  margin-bottom: 0;
}

.page_header_content {  
  text-align: center; 
}
.main_wrapper{
  margin-top: -100px;
}
.detailbox {
  max-width: 75%;
  margin: 0 auto;
}
.detailbox .box{
  background: url("../src/assets/images/boxbg.png") no-repeat center;
  padding: 40px 0;
  border-radius: 25px;
  background-size: 100% 100%;
}
.about h4{
  font-size: 36px;
  font-family: 'Atomed Regular';
  margin-left: 50px;
}
.about p{
  max-width: 50%;
  margin-left: auto;
}
.about img{
  margin-bottom: -20px;
}

.about_box {
  max-width: 75%;
  margin: 0 auto;
  background: url("../src/assets/images/aboubox_bg1.png") no-repeat center;
  background-size: 100% 100%;
  margin-top: -40px;
} 

.about_box  .about_boxbg {
  /* background: #364c81; */
  /* border-radius: 15px; */
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-width: 75%;
  margin: 0px auto;
} 
.about_box  .about_boxbg .box {
  padding-top: 90px;
}
.about_box  .about_boxbg h3{
  font-size: 36px;
  color: #ff919d;
  font-family: 'Atomed Regular';
}
/* .about_box  .about_boxbg::before {
  content: "";
  position: absolute;
  background: #615297;
  left: 0;
  top: 5%;
  width: 20%;
  height: 50%;
  filter: blur(61px);
  z-index: -1;
} */

section {
  padding: 40px 0;
}
.h2tag{
  font-size: 48px;
  font-family: 'Atomed Regular';
  text-align: center;
  margin-bottom: 25px;
}
.whatsnew h2{
  margin-bottom: 40px;
}
.whatsnew h3{
  font-size: 30px;
  color: #ff919d;
  font-family: 'Atomed Regular';
}
.whatsnew .imgbox{
  position: relative;
  text-align: center;
}
.whatsnew .imgbox::before{
  content: "";
  position: absolute;
  background: #65527e;
  width: 60%;
  height: 85%;
  border-radius: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: -1;
  filter: blur(41px);
}
.visitor_table{
  max-width: 75%;
  margin: 0 auto;
}

.visitor_table  thead tr,
.visitor_table table tbody tr:nth-last-child(1) {
  background: linear-gradient(93deg, #8c74b8, #6974c6);
}
.visitor_table   thead th{
  background: transparent;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0;
}
.visitor_table .table-responsive{
  border-radius: 20px;
}
.visitor_table table{
  border: 1px solid transparent;
  color: #fff;
  font-size: 22px;
  vertical-align: middle;
  margin: 0;
}
.visitor_table table tbody tr{
  background: linear-gradient(90deg, #5874c9, #f67280 199%);
}
.visitor_table table tbody tr:nth-child(2){
  background: linear-gradient(270deg, #5874c9, #f67280 199%);
}
.visitor_table td div,
.visitor_table th div{
    width: 150px;
    margin: auto;
}
.visitor_table td:nth-child(1) ,
.visitor_table th:nth-child(1) {
  padding-left: 20px;
}

.faq_section .head{
 max-width: 60%;
 margin:0 auto;
 text-align: center !important;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 30px;
}
.faq_section .head h2{
  text-align: center !important;
}
.faq_section .head a{
  text-decoration: underline;
  color: #ff919d;
}
.faq_section .head h2{
  margin-bottom: 15px;
}
.faq_section .head button{
  margin: unset;
}
.main_title_small {
  font-size: 20px;
  font-weight: 300;
  color: #09bbfe;
}

.main_title {
  font-size: 46px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
}

.main_subtitle {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
}

.inner_para {
  font-size: 18px;
  font-weight: 400; 
}

.primary_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  background: rgba(53, 71, 125, 0.3);
  /* background: linear-gradient(0deg, rgba(6, 6, 18, 1) 0%, rgba(9, 12, 32, 1) 100%); */
  background-clip: padding-box;
  border: 1px solid #465ea6;
  border-radius: 20px;
  transition: 0.2s all;
  overflow: hidden;
  box-shadow: inset 0 0 18px 10px rgb(39 55 103 / 50%), 0 0 10px 7px rgb(39 55 103 / 80%);
}
.detailbox .box{
  text-align: center;
}
.detailbox .box p{
  font-size: 20px;
  font-weight: 500;
}
.detailbox .box h5{
  font-size: 24px;
  font-weight: 400;
  font-family: 'Atomed Regular';
  color: #06143d;
}
.features_section,
.income_section,
.faq_section {
  position: relative;
}

.income_section,
.faq_section {
  z-index: 50;
}

.feature_row_setion,
.income_section_row {
  position: relative;
}

.features_section::before,
.income_section::before {
  content: "";
  background: url("./assets/images/shape_01.png") no-repeat top;
  background-size: 100%;
  width: 100%;
  height: 573px;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  z-index: -1;
}

.features_section::after,
.income_section::after,
.income_plan_section::after {
  content: "";
  background: url("./assets/images/dotted_shape.png") no-repeat;
  width: 206px;
  height: 372px;
  position: absolute;
  right: 30px;
  z-index: -1;
}

.income_section::after {
  right: unset;
  left: 30px;
}

.feature_row_setion::before,
.income_section_row::before {
  content: "";
  background: url("./assets/images/shape_02.png") no-repeat top;
  background-size: 100%;
  width: 100%;
  height: 335px;
  position: absolute;
  top: 0;
  left: -15px;
  right: 0;
  z-index: -1;
}

.income_section::before {
  top: 0;
}

section.income_plan_section {
  position: relative;
  z-index: 50;
}

.features_section .animated {
  height: 100%;
}

.feature_row_setion {
  margin-top: 180px;
}

.features_box {
  cursor: default;
}

.features_box img {
  margin-bottom: 20px;
}

.features_box h4 {
  font-size: 30px;
  font-weight: 500;
  color: #09bbfe;
  margin-bottom: 10px;
}

.features_box>div {
  margin-bottom: 20px;
}

.primary_box p {
  margin-bottom: 0;
}

.features_section [class*="col-"] {
  margin-bottom: 30px;
}

section.faq_section {
  z-index: 50;
}

.income_section .main_subtitle {
  max-width: 50%;
  margin: 0 auto 30px;
}

.tree_struc {
  text-align: center;
  margin-top: 150px;
}

.plan_income_table {
  border: 2px solid transparent;
  border-radius: 10px;
  background: transparent;
  overflow: hidden;
  margin: 40px 0 0;
}

.plan_income_table th,
.plan_income_table td {
  border: 0 !important;
  vertical-align: middle;
  white-space: nowrap;
}

.plan_income_table th {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
}

.plan_income_table td {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
}

.plan_income_table th:first-child,
.plan_income_table td:first-child {
  padding-left: 30px;
  width: 150px;
}

.plan_income_table th:last-child,
.plan_income_table td:last-child {
  padding-right: 30px;
}

.plan_income_table th:first-child {
  border-radius: 0 0 0 10px;
}

.plan_income_table th:last-child {
  border-radius: 0 0 10px 0;
}

.plan_income_table th:last-child,
.plan_income_table td:last-child,
.plan_income_table th:nth-child(3),
.plan_income_table td:nth-child(3) {
  text-align: right;
}

.plan_income_table_div {
  border-radius: 10px;
}

.plan_income_table thead tr {
  border-radius: 10px;
}

.plan_income_table thead {
  background: rgb(111,144,255);
  background: linear-gradient(270deg, rgba(111,144,255,1) 0%, rgba(246,114,128,1) 100%);
}

.plan_income_table tbody tr {
  border-bottom: 1.5px solid #465ea6;
  border-left: 0;
  border-right: 0;
  transition: 0.2s all;
  cursor: default;
}

.plan_income_table tbody tr:nth-child(even) {
  background-color: transparent;
}

.plan_income_table tbody tr:nth-child(odd) {
  background-color: transparent;
}
.plan_income_table tbody tr:hover {
  background: rgb(39,55,103);
  background: linear-gradient(90deg, rgba(39,55,103,1) 0%, rgba(108,136,242,1) 100%);
}
.plan_income_table td h4 {
  font-size: 30px;
  font-weight: 400;
  margin: 3px 0;
  color: #09bbfe;
}

.plan_income_table tbody tr.footer_row {
  background-color: #1d1d41;
}

.faq_content h2 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.faq_content p {
  font-size: 18px;
  font-weight: 300;
  color: #bfbfe6;
  margin-bottom: 0;
}
.faq_content ol {
  font-weight: 300;
    color: #bfbfe6;
    margin-bottom: 0;
}
.faq_content .flx{
  display: flex;
}
.faq_content ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-weight: 300;
    color: #bfbfe6;
}
.faq_box {
  background: #4a62ab;
  padding: 40px;
  margin: 0 30px 0 0;
  border-radius: 10px;
  min-height: 270px;
}

.faq_carousel .slick-next,
.faq_carousel .slick-prev {
  top: -65px;
}

.faq_carousel .slick-prev {
  right: 130px;
  left: unset;
}

.faq_carousel .slick-next {
  right: 56px;
}

.faq_carousel .slick-prev::before {
  content: "";
  background: url("./assets/images/prev_arrow.png");
  width: 35px;
  height: 28px;
  position: absolute;
}

.faq_carousel .slick-prev:hover::before {
  background: url("./assets/images/prev_arrow_hover.png");
}

.faq_carousel .slick-next::before {
  content: "";
  background: url("./assets/images/next_arrow.png");
  width: 35px;
  height: 28px;
  position: absolute;
}

.faq_carousel .slick-next:hover::before {
  background: url("./assets/images/next_arrow_hover.png");
}

.footer {
  background-color: #394d8c;
  padding: 30px 0 20px;
}

.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding-bottom: 15px; 
}

.footer_panel_left p {
  margin-bottom: 0;
  font-size: 16px;
}

.footer p a {
  color: #fff;
}

.footer_panel_left a {

  font-size: 16px;
  color: #80a8ed;
  white-space: normal;
  word-break: break-all;
}

.footer_social_links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer_social_links li {
  margin: 0 8px;
}

.footer_social_links li a {
  width: 44px;
  height: 44px;
  background: #4d69bf;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #394d8c;
  font-size: 18px;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: #fff; 
  color: #394d8c;
}

.primary_btn::before {
  background: transparent;
}

.primary_btn::before {
  position: absolute;
  content: "";
  top: 0;
  right: -1px;
  bottom: -1px;
  left: 0;
  border-radius: 0;
  transform: none;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

/* .primary_btn:hover,
.primary_btn:active,
.primary_btn.active {
  color: #060612;
  background: #09bbfe;
  border-color: #09bbfe;
} */

.primary_btn:hover::before,
.primary_btn:active::before,
.primary_btn.active::before {
  border-radius: 30px;
  transform: translate3d(-101%, 0, 0);
}

.primary_btn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center; 
  padding: 8px 42px 8px 25px;
  font-size: 20px;
  line-height: 1.715;
  border:0;
  border-radius: 50px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  color: #ffffff;
  text-decoration: none !important;
  background: url("../src/assets/images/btnbg.png") no-repeat center;
  background-size: 100% 100%;
  z-index: 1;
  margin: 30px auto 0;
}

button.primary_btn.disabled {
  cursor: auto;
}

.navbar_right .primary_btn {
  margin: 0;
  outline: 0;
}

.d_d_none {
  display: none !important;
}

.inner_para a {
  word-break: break-all;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
}

.inner_wrapper::before {
  content: "";
  background: url("../src/assets/images/dash_bg_before.png") no-repeat;
  width: 100%;
  height: -webkit-fill-available;
  background-size: cover;
  background-position: top center;
  position: absolute;
  top: -90px;
  z-index: -1;
}

.inner_wrapper::after {
  content: "";
  background: url("../src/assets/images/dash_bg_after.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: bottom center;
  position: absolute;
  bottom: 20%;
  z-index: -1;
}
/* 
.dashboard_panel {
  background: #060612;
  padding: 30px;
  border-radius: 30px;
  background-image: radial-gradient(circle at 100% 100%, transparent 28px, #003f5a 28px, #003f5a 30px, transparent 30px), linear-gradient(to right, #003f5a, #003f5a), radial-gradient(circle at 0% 100%, transparent 28px, #003f5a 28px, #003f5a 30px, transparent 30px), linear-gradient(to bottom, #003f5a, #060612), radial-gradient(circle at 0% 0%, transparent 28px, #060612 28px, #060612 30px, transparent 30px), linear-gradient(to left, #060612, #060612), radial-gradient(circle at 100% 0%, transparent 28px, #060612 28px, #060612 30px, transparent 30px), linear-gradient(to top, #060612, #003f5a);
  background-size: 30px 30px, calc(100% - 60px) 2px, 30px 30px, 2px calc(100% - 60px);
  background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat;
} */

.dashboard_panel.join_now_panel {
  min-height: 92vh;
}

.title_flex {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.dash_title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.inner_title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}

.wallet_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet_div label {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  margin: 0px 5px 0 0;
}

.primary_inp {
  border: 1px solid #465ea6;
  background: #6f90ff;
  color: #fff !important;
  font-size: 16px;
  border-radius: 10px;
  padding: 12px;
}

.primary_inp:focus {
  border: 1px solid #465ea6;
  background: #6f90ff;
  box-shadow: none !important;
}

.primary_inp::placeholder {
  color: #fff;
}

.inp_grp {
  position: relative;
}

.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}

.wallet_div input.primary_inp {
  width: 350px;
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  color: #fff !important;
  font-weight: 300;
  font-size: 16px;
  padding: 8px 15px;
  padding-right: 40px;
}

button.round_btn {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.copy_btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 5px;
}

img.copy_icon.wallet_icon {
  margin-right: 0;
}

.wallet_div .inp_grp {
  display: flex;
  align-items: center;
}

.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #031542;
}

.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.dash_box_left:first-child {
  padding-bottom: 20px;
}

.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}

.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.dash_box_left h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}

.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}

.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}

.dash_box_left h4 {
  color: #e8d7f8;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}

.dashbox_middle_top h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.dashbox_middle_top h4 {
  font-size: 18px;
  font-weight: 400;
  color: #617398;
}

.dashbox_middle_top>div {
  display: flex;
  align-items: center;
  cursor: default;
}

.dashbox_middle_bottom h2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
  padding-bottom: 0;
}
/* 
.primary_box.dash_box.side_box .dashbox_middle_bottom h2,
.primary_box.dash_box.side_box .dashbox_middle_bottom h5 {
  text-align: right;
} */

.balance_box {
  background: rgba(53, 71, 125, 0.3);  
  box-shadow: inset 0 0 18px 10px rgb(39 55 103 / 50%), 0 0 15px 5px rgb(39 55 103 / 80%);
  border: 1px solid #465ea6;
  padding: 30px 40px;
  border-radius: 15px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
}

.balance_box>div {
  width: 33%;
  text-align: center;
  position: relative;
}
.balance_box>div::after {
  content: "";
  background: #9f6e90;
  position: absolute;
  width: 2px;
  height: 83px;
  right: 0;
  top: -10px;
}
.balance_box>div:last-child:after {
  display: none;
}
.balance_box div label {
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.balance_box div label:nth-child(1) {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.balance_box div label:nth-child(1) span {
  font-weight: 700;
}

.dashbox_middle_bottom h5 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 5px;
}

.dashboard_top_wrap [class*="col-"] {
  margin-bottom: 30px;
}

.dashbox_middle_bottom ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
}

.dashbox_middle_bottom ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}

.dashbox_middle_bottom ul.footer_social_links li {
  margin: 0 10px 0px 0;
}

.dashboard_top_wrap>[class*="col-"]:last-child {
  margin-bottom: 0;
}

.income_wrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.income_div {
  border: 1px solid #111737;
  background: rgb(6, 6, 18);
  background: linear-gradient(0deg, rgba(6, 6, 18, 1) 0%, rgba(9, 12, 32, 1) 100%);
  border-radius: 15px;
  padding: 30px;
  position: relative;
}

.income_div::after {
  content: "";
  /* background: url("./assets/images/dash_box_shape.png") no-repeat right bottom; */
  width: 77px;
  height: 85px;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.65;
}

.income_div h3 {
  font-size: 16px;
  color: #09bbfe;
  font-weight: 300;
}

.income_div h4 {
  font-size: 26px;
  color: #fff;
  font-weight: 500;
}

.income_div h4 span {
  color: #9f9f9f;
  font-weight: 300;
}

.search_div {
  width: 50%;
  margin: 50px auto 0;
}

.search_div button {
  background: #feba31;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: #060612;
  position: absolute;
  top: 1px;
  right: 0;
  border-radius: 0 10px 10px 0;
  padding: 6px 20px;
}

.plan_wrapper {
  /* display: flex;
  flex-flow: wrap;
  justify-content: center; */
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-top: 15px;
}

.buy_item {
  background: #273767;
  border: 1px solid #465ea6;
  box-shadow: inset 0 0 18px 10px rgb(39 55 103 / 50%), 0 0 10px 7px rgb(39 55 103 / 80%);
  padding: 20px 10px 20px;
  border-radius: 20px;
  position: relative;
  /* width: 200px; */
  margin: 0 0 20px;
  cursor: default;
  display: flex;
}
.progress_custom {
  width: 36px;
  height: 120px;
  border-radius: 10px;
  background: #425da6;
  border: 1px solid #465ea6;
  margin: 0 auto;
  transform: rotate(180deg);
}
.progress_custom .progress-bar {
  width: 100%;
  background: linear-gradient(270deg, rgba(111,144,255,1) 0%, rgba(246,114,128,1) 100%);
  writing-mode: tb;
}
.buy_item.buy_item_upgraded {
  border: 2px solid #1abefe;
}
.buy_item_inner_left div:first-child {
  border-bottom: 1px solid rgb(111 144 255 / 70%);
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.buy_item h2 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.buy_item h2 span {
  font-weight: 300;
}
.plan_income_table_flex_div {
  display: flex;
  column-gap: 30px;
  align-items: flex-start;
  margin-top: 15px;
}
.plan_income_table_flex_div .plan_income_table_div {
  width: 50%;
}
.buy_item h4 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 5px;
}

.buy_item h5 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.buy_item h5 span {
  font-weight: 300;
}
.buy_item_inner_left {
  width: 70%;
}
.buy_item_inner_right {
  width: 30%;
}
.buy_item .primary_btn {
  padding: 4px 12px;
  font-size: 16px;
  margin: 15px auto 0;
  width: auto !important;
}

/*.dashbox_middle_bottom .primary_btn {
  width: 100%;
  justify-content: center;
}*/
.primary_btn.primary_btn_right {
  margin-right: 0 !important
}
.dash_flex_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 15px; */
}

.dash_btn_grp {
  display: flex;
  align-items: center;
}

.dash_btn_grp button.primary_btn {
  margin-top: 0;
  text-transform: capitalize;
  padding: 2px 25px;
  background: #09bbfe;
  color: #060612;
}

.dash_btn_grp button.primary_btn:hover {
  background: transparent;
  color: #09bbfe;
}

.dash_btn_grp button+button {
  margin-left: 8px;
}

.buy_count {
  background: url("./assets/images/level_bg.png");
  position: relative;
  margin: -75px auto 15px;
  width: 111px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buy_item_upgraded .buy_count {
  background: url("./assets/images/upgraded_level_bg.png");
  width: 111px;
  height: 111px;
}

.buy_item_partial_upgraded .buy_count {
  background: url("./assets/images/partial_level_bg.png");
  width: 110px;
  height: 110px;
}

.buy_count p {
  font-size: 20px;
  color: #9f9f9f;
  font-weight: 700;
  text-align: center;
}

.buy_count p span {
  color: #09bbfe;
  display: block;
}

.buy_count h2 {
  font-size: 36px;
  color: #060612;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.buy_count h3 {
  font-size: 24px;
  color: #060612;
  font-weight: 700;
  margin: 0;
}

.buy_data {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.buy_data_item {
  display: flex;
  align-items: center;
}

.buy_data_count p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  margin-left: 5px;
}

.chance_panel h2 {
  font-size: 24px;
  text-transform: uppercase;
}

.buy_item.buy_item_disabled {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}
.form-control:disabled{
  background-color: #060612;
}

.buy_item:hover .buy_count {
  animation-name: hvr-pulse;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.primary_box.dash_box {
  padding: 20px;
}

.primary_box.dash_box.side_box > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(111 144 255 / 70%);
  padding-bottom: 30px;
}
.primary_box.dash_box.side_box > div:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #394d89;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #4c67b9;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid rgb(76 103 185);
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}

.connect_wallet_button:hover {
  border-color: #6d89dd;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  box-shadow: none !important;
}

.primary_modal a {
  text-decoration: none;
}

.node circle {
  fill: #feba31 !important;
  stroke: #feba31 !important;
  stroke-width: 1.5px !important;
  background: url("../src/assets/images/tree_icon.png");
  width: 13px;
  height: 21px;
}

.node text {
  font-size: 11px !important;
  background-color: #444 !important;
  fill: #fff !important;
}

.node {
  cursor: pointer;
}

path.link {
  stroke: #1d1d41 !important;
  stroke-width: 1.5px !important;
}

.dash_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  cursor: pointer;
  margin-right: 15px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #feba31;
  box-shadow: 0 0 0 3px #feba31;
  transition: color 0.3s;
}

.dash_icon:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #feba31;
  transition: transform 0.2s, opacity 0.2s;
}

.dash_icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dashbox_middle_top>div:hover .dash_icon::after {
  transform: scale(0.85);
}

.address_btn {
  display: inline-block;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 200px;
}

ul.walletdrop {
  background: #111f3d;
  border: 1px solid #223359;
}

ul.walletdrop li a {
  color: #eee;
  text-decoration: none;
}

ul.walletdrop li a:hover {
  background-color: #35477d;
  color: #fff;
}

.errorText {
  color: red;
}
.terms_content {
  margin-top: 30px;
}
.terms_content p {
  margin-bottom: 30px;
  line-height: 26px;
}
.footer_menu_links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.footer_menu_links li+li {
  margin-left: 20px;
}
.footer_menu_links li a {
  color: #fff;
}
.footer_menu_links li a:hover {
  color: #09bbfe;
}
.instagram{
  font-size: 20px;
}



.accordion-button::after {
  flex-shrink: 0;
  width: 11px;
  height: 20px;
  margin-left: auto;
  content: "";
  background-image: url("./assets/images/accordian_normal.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/accordian_normal.png");
  transform: rotate(90deg);
}
h2.accordion-button {
  margin-bottom: 0;
  cursor: pointer;
}
.balance_box_two_div > div {
  width: 50%;
}
.balance_box_two_div > div button.primary_btn {
  margin: 8px auto;
  padding: 4px 20px;
  font-size: 14px;
}
.earningtypes h5 {
  font-size: 16px;
  color: #bec0ff;

}
.earningtypes p{
  text-transform:capitalize;
} 

.scroll{
  display: flex;
  flex-direction: column !important;
  max-height: 622.91px;
  overflow-y: scroll;
}
.scroll> div{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(111 144 255 / 70%);
  padding-bottom: 30px;
  width: 100%;
}
.scroll> div:last-child{
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  .faq_box{
    min-height: 300px;
  }
}
@media screen and (max-width: 1199px) {
  .faq_box {
    min-height: 375px;
}
.faq_content .flx {
  display: block;
}
  .dashbox_middle_top h2 {
    font-size: 32px;
  }

  .plan_wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .dash_icon {
    width: 90px;
    height: 90px;
  }

 
  .page_header h1 {
    font-size: 126px;
  }
  .genealogy_tree {
    width: 600px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}

@media screen and (max-width: 991px) {
  .faq_box{
    min-height: unset;
    height: 100%;
  }
  .inner_title {
    margin-bottom: 15px;
  }
  .about_box {
    max-width: 100%;
  }
  .about_box .row{
    align-items: center;
  }
  .wallet_div .inp_grp {
    justify-content: center;
  }

  .wallet_div label {
    margin: 0 !important;
  }

  .footer_panel {
    display: block;
    text-align: center;
  }

  .footer_social_links,
  .footer_menu_links {
    justify-content: center;
    margin-top: 15px;
  }
  .page_header {
    padding: 20px 0 190px;
  }
  .page_header h1 { 
    text-align: center;
    max-width: 100%;
    margin: 0 auto 20px;
  }

  .page_header_content {
    display: block;
    text-align: center;
    margin-top: 150px;
  }

  .page_header h5 {
    max-width: 80%;
    text-align: center;
    margin: 0 auto 20px;
  }

  .main_title {
    font-size: 36px;
  }

  .d_m_none {
    display: none !important;
  }

  .d_d_none {
    display: block !important;
  }

  .dashbox_middle_top {
    flex-wrap: wrap;
  }

  .dashbox_middle_top>div {
    margin-bottom: 15px;
  }

  .buy_count h2 {
    font-size: 28px !important;
    line-height: 32px;
  }

  .buy_count h3 {
    font-size: 20px;
  }

  .dash_title_flex {
    display: block;
    text-align: center;
  }

  .main_navbar {
    background-color: #3c5395 !important;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar_right {
    margin-top: 15px;
    flex-direction: column;
  }
  .navbar_right .dropdown{
     margin-right: 0 !important;
     margin-bottom: 10px;
  }
  .balance_box,
  .dash_flex_header {
    display: block;
  }

  .balance_box>div {
    width: 100%;
    margin-bottom: 20px;
  }
  .balance_box>div:last-child {
    margin-bottom: 0;
  }
  .income_section .main_subtitle {
    max-width: 100%;
  } 
  .faq_section .head{
    max-width: 100%;
    text-align: center;
    margin-bottom: 80px;
  }
  .footer{
    text-align: center;
  }
  .balance_box>div::after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .faq_box {
    padding: 20px;
  }
  .h2tag,.about_box .about_boxbg h3{
    font-size: 35px;
  }
  .whatsnew {
    text-align: center;
  }
  .whatsnew h3 {
    font-size: 26px;
  }
  .whatsnew h2 {
    margin-bottom: 20px;
}
.whatsnew_flex .row{
  flex-direction: column-reverse;
}
.visitor_table{
  max-width: 100%;
}
.visitor_table thead th { 
  font-size: 22px;
}
.about_box{
  background: none;
}
.about_box .about_boxbg{
  max-width: 100%;
  background: linear-gradient(45deg, #5e5394, #3c538c);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0 #0000009e;
}
.about_box .about_boxbg .box{
  padding-top: 10px;
}
.about,.about_box{
  text-align: center;
}
.about_box img{
  margin-bottom: 15px;
}
.about h4{
  margin-left: 0;
}
.about img{
  margin-bottom: 0;
}
.about p{
  max-width: 100%;
}
.faq_section .head {
  margin-bottom: 40px;
}
.visitor_table table{
  font-size: 20px;
}
  .dashbox_middle_top {
    display: block;
  }

  .dashbox_middle_top>div {
    margin-bottom: 30px;
  }

  .dashbox_middle_top>div:last-child {
    margin-bottom: 0;
  }

  .dashbox_middle_top h2 {
    margin-bottom: 0;
  }

  .search_div {
    width: 100%;
  }

  .footer_panel_left a {
    font-size: 14px;
  }

  button.logout_btn {
    margin-bottom: 15px;
  }

  .page_header h1 {
    font-size: 96px;
}

  .page_header h5 {
    max-width: 100%;
  }
  .detailbox .box{
    margin-bottom: 15px;
  }
  .main_title {
    font-size: 32px;
  }

  .faq_section .text-right {
    text-align: left !important;
  }

  .plan_wrapper,
  .income_wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .inner_title {
    font-size: 36px;
  }

  .wallet_div {
    display: block;
  }

  .wallet_div input.primary_inp {
    width: 100%;
  }

  .faq_carousel {
    margin-top: 50px;
  }

  .faq_box {
    margin: 0 10px;
  }
  .features_section::after,
  .income_section::after,
  .income_plan_section::after {
    opacity: 0.2;
  }
  /* .page_header {
    height: 650px;
  } */
  .genealogy_tree {
    width: 450px;
  }
}

@media screen and (max-width: 575px) {

  .dashbox_middle_bottom h2 {
    font-size: 18px;
  }

  .inner_para {
    font-size: 18px;
  }

  .main_title_small {
    font-size: 18px;
  }

  .main_title {
    font-size: 28px;
  }

  .main_navbar .container-fluid {
    display: block;
    text-align: center;
  }

  .primary_btn {
    padding: 12px 25px;
    font-size: 16px;
  }

  .plan_wrapper,
  .income_wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .wallet_div input.primary_inp {
    text-overflow: ellipsis;
  }

  .dash_icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
  }

  .dashbox_middle_top>div {
    display: block;
    text-align: center;
  }

  .dashbox_middle_top h2 {
    text-align: center;
    font-size: 28px;
  }

  .dash_icon img {
    width: 32px;
  }

  .page_header_content {
    margin-top: 110px;
  }

  .plan_income_table td h4 {
    font-size: 22px;
  }

  .page_header h1 {
    font-size: 68px;
}
  .dash_btn_grp button.primary_btn{
    font-size: 15px;
  }
  .dash_btn_grp {
    flex-direction: column;
  }
  .dash_btn_grp button{
    margin-bottom: 10px;
  }
  .dash_btn_grp button+button{
    margin-left: 0;
  }
  .plan_income_table_flex_div {
    flex-wrap: wrap;
  }
  .plan_income_table_flex_div .plan_income_table_div {
    width: 100%;
  }
}
@media screen and (max-width: 440px)  {
  .genealogy_tree {
    width: 250px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 400px) {
  .page_header h1 {
    font-size: 50px;
}
}

/* Banner Stars and Cloud Animation Starts */
.shootingStarContainer:nth-child(1) {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(39deg);
  z-index: 10;
}

.shootingStarContainer:nth-child(2) {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(36deg);
  z-index: 10;
}

.shootingStarContainer:nth-child(3) {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(44deg);
  z-index: 10;
}

.shootingStar {
  position: absolute;
  height: 1px;
  background: linear-gradient(-70deg, #ffebeb, black);
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(1) {
  left: calc(24%);
  top: calc(64%);
  -webkit-animation: shootingFrames 1962ms ease-in-out 26913ms;
  animation: shootingFrames 1962ms ease-in-out 26913ms;
  opacity: 0.9;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(2) {
  left: calc(21%);
  top: calc(23%);
  -webkit-animation: shootingFrames 2323ms ease-in-out 56663ms;
  animation: shootingFrames 2323ms ease-in-out 56663ms;
  opacity: 0.49;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(3) {
  left: calc(1%);
  top: calc(69%);
  -webkit-animation: shootingFrames 1037ms ease-in-out 36980ms;
  animation: shootingFrames 1037ms ease-in-out 36980ms;
  opacity: 0.43;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(4) {
  left: calc(1%);
  top: calc(74%);
  -webkit-animation: shootingFrames 2340ms ease-in-out 57554ms;
  animation: shootingFrames 2340ms ease-in-out 57554ms;
  opacity: 0.4;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(5) {
  left: calc(8%);
  top: calc(50%);
  -webkit-animation: shootingFrames 2595ms ease-in-out 25430ms;
  animation: shootingFrames 2595ms ease-in-out 25430ms;
  opacity: 0.44;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(6) {
  left: calc(22%);
  top: calc(13%);
  -webkit-animation: shootingFrames 1620ms ease-in-out 25214ms;
  animation: shootingFrames 1620ms ease-in-out 25214ms;
  opacity: 0.35;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(7) {
  left: calc(15%);
  top: calc(50%);
  -webkit-animation: shootingFrames 853ms ease-in-out 60036ms;
  animation: shootingFrames 853ms ease-in-out 60036ms;
  opacity: 0.24;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(8) {
  left: calc(47%);
  top: calc(9%);
  -webkit-animation: shootingFrames 1035ms ease-in-out 12656ms;
  animation: shootingFrames 1035ms ease-in-out 12656ms;
  opacity: 0.82;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(9) {
  left: calc(2%);
  top: calc(9%);
  -webkit-animation: shootingFrames 2006ms ease-in-out 29931ms;
  animation: shootingFrames 2006ms ease-in-out 29931ms;
  opacity: 0.75;
}

.shootingStarContainer:nth-child(1) .shootingStar:nth-child(10) {
  left: calc(45%);
  top: calc(77%);
  -webkit-animation: shootingFrames 1791ms ease-in-out 54841ms;
  animation: shootingFrames 1791ms ease-in-out 54841ms;
  opacity: 0.68;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(1) {
  left: calc(25%);
  top: calc(54%);
  -webkit-animation: shootingFrames 1932ms ease-in-out 53238ms;
  animation: shootingFrames 1932ms ease-in-out 53238ms;
  opacity: 0.89;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(2) {
  left: calc(32%);
  top: calc(67%);
  -webkit-animation: shootingFrames 984ms ease-in-out 10103ms;
  animation: shootingFrames 984ms ease-in-out 10103ms;
  opacity: 0.67;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(3) {
  left: calc(32%);
  top: calc(75%);
  -webkit-animation: shootingFrames 2148ms ease-in-out 58490ms;
  animation: shootingFrames 2148ms ease-in-out 58490ms;
  opacity: 0.57;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(4) {
  left: calc(32%);
  top: calc(96%);
  -webkit-animation: shootingFrames 2481ms ease-in-out 49420ms;
  animation: shootingFrames 2481ms ease-in-out 49420ms;
  opacity: 0.23;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(5) {
  left: calc(46%);
  top: calc(84%);
  -webkit-animation: shootingFrames 1921ms ease-in-out 27900ms;
  animation: shootingFrames 1921ms ease-in-out 27900ms;
  opacity: 0.67;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(6) {
  left: calc(9%);
  top: calc(69%);
  -webkit-animation: shootingFrames 759ms ease-in-out 38884ms;
  animation: shootingFrames 759ms ease-in-out 38884ms;
  opacity: 0.63;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(7) {
  left: calc(43%);
  top: calc(68%);
  -webkit-animation: shootingFrames 1346ms ease-in-out 48357ms;
  animation: shootingFrames 1346ms ease-in-out 48357ms;
  opacity: 0.71;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(8) {
  left: calc(12%);
  top: calc(11%);
  -webkit-animation: shootingFrames 2441ms ease-in-out 996ms;
  animation: shootingFrames 2441ms ease-in-out 996ms;
  opacity: 0.21;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(9) {
  left: calc(11%);
  top: calc(85%);
  -webkit-animation: shootingFrames 1619ms ease-in-out 50965ms;
  animation: shootingFrames 1619ms ease-in-out 50965ms;
  opacity: 0.3;
}

.shootingStarContainer:nth-child(2) .shootingStar:nth-child(10) {
  left: calc(30%);
  top: calc(66%);
  -webkit-animation: shootingFrames 1339ms ease-in-out 58871ms;
  animation: shootingFrames 1339ms ease-in-out 58871ms;
  opacity: 0.23;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(1) {
  left: calc(18%);
  top: calc(75%);
  -webkit-animation: shootingFrames 1045ms ease-in-out 28219ms;
  animation: shootingFrames 1045ms ease-in-out 28219ms;
  opacity: 0.56;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(2) {
  left: calc(22%);
  top: calc(42%);
  -webkit-animation: shootingFrames 2525ms ease-in-out 54400ms;
  animation: shootingFrames 2525ms ease-in-out 54400ms;
  opacity: 0.83;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(3) {
  left: calc(38%);
  top: calc(54%);
  -webkit-animation: shootingFrames 2186ms ease-in-out 28702ms;
  animation: shootingFrames 2186ms ease-in-out 28702ms;
  opacity: 0.34;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(4) {
  left: calc(29%);
  top: calc(31%);
  -webkit-animation: shootingFrames 1588ms ease-in-out 29934ms;
  animation: shootingFrames 1588ms ease-in-out 29934ms;
  opacity: 0.65;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(5) {
  left: calc(6%);
  top: calc(86%);
  -webkit-animation: shootingFrames 1813ms ease-in-out 4922ms;
  animation: shootingFrames 1813ms ease-in-out 4922ms;
  opacity: 0.19;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(6) {
  left: calc(36%);
  top: calc(87%);
  -webkit-animation: shootingFrames 2497ms ease-in-out 36358ms;
  animation: shootingFrames 2497ms ease-in-out 36358ms;
  opacity: 0.66;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(7) {
  left: calc(40%);
  top: calc(67%);
  -webkit-animation: shootingFrames 991ms ease-in-out 41703ms;
  animation: shootingFrames 991ms ease-in-out 41703ms;
  opacity: 0.47;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(8) {
  left: calc(46%);
  top: calc(58%);
  -webkit-animation: shootingFrames 2182ms ease-in-out 12200ms;
  animation: shootingFrames 2182ms ease-in-out 12200ms;
  opacity: 0.89;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(9) {
  left: calc(41%);
  top: calc(56%);
  -webkit-animation: shootingFrames 2208ms ease-in-out 27959ms;
  animation: shootingFrames 2208ms ease-in-out 27959ms;
  opacity: 0.87;
}

.shootingStarContainer:nth-child(3) .shootingStar:nth-child(10) {
  left: calc(25%);
  top: calc(87%);
  -webkit-animation: shootingFrames 1331ms ease-in-out 17994ms;
  animation: shootingFrames 1331ms ease-in-out 17994ms;
  opacity: 0.9;
}

@-webkit-keyframes shootingFrames {
  0% {
    transform: translateX(0);
    width: 0;
  }

  30% {
    width: 200px;
  }

  100% {
    width: 0;
    transform: translateX(844px);
  }
}

@keyframes shootingFrames {
  0% {
    transform: translateX(0);
    width: 0;
  }

  30% {
    width: 200px;
  }

  100% {
    width: 0;
    transform: translateX(844px);
  }
}

.stars,
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background: #060612 url("./assets/images/star_bg.png") no-repeat top center;
  background-size: cover;
  z-index: -1;
}

.clouds {
  background: transparent url("https://wolf.drjm.co.uk/nightsky/clouds.png") no-repeat top center;
  z-index: 3;
  height: 100%;
  -webkit-animation: cloudsFrames 300s linear infinite;
  animation: cloudsFrames 300s linear infinite;
}

@-webkit-keyframes cloudsFrames {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 10000px 0;
  }
}

@keyframes cloudsFrames {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 10000px 0;
  }
}

@media screen and (max-width: 540px) {
  .page_header {
    background-size: 250% !important;
  }
}

/* Banner Stars and Cloud Animation Ends */